<!-- <div class="grid grid-cols-1 flex-col gap-2x md:grid-cols-2 2xl:grid-cols-2">
  @for (order of purchaseOrders; track $index) {
  <order-card [data]="order"></order-card>
  }
</div>

 -->

<mat-table
  [ngClass]="tableClasses"
  [dataSource]="dataSource"
  matSort
  [@.disabled]="true"
  multiTemplateDataRows
>
  <!-- GRID VIEW COL - Use as a single column -->
  <ng-container matColumnDef="product_grid_item">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <order-card [data]="row" class="h-full"></order-card>
    </mat-cell>
  </ng-container>

  <mat-row *matRowDef="let row; columns: columns;"></mat-row>
</mat-table>

@if (dataSource.isLoading) {
<div class="w-full py-6x">
  <ngx-skeleton-loader
    class="size-16"
    [count]="3"
    [theme]="{
              height: '6rem',
              opacity: 0.5,
              background: 'var(--grey-alt)',
            }"
  ></ngx-skeleton-loader>
</div>
}

<!-- 
<data-table-footer
  [localStorageKey]="LOCAL_STORAGE_KEY"
  [isLoading]="isLoading"
  (paginator)="hookPaginator($event)"
  [search]="search"
  (onSearch)="onSearch($event)"
  (onLoad)="onLoad()"
>
</data-table-footer> -->
